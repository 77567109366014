'use client'
import { Button } from '@/components/ui/button'
import { Label } from '@/components/ui/label'
import { Textarea } from '@/components/ui/textarea'
import { useToast } from '@/components/ui/use-toast'
import { sendContactReply } from '@/services/api/contact'
import { X } from 'lucide-react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

interface ContactMessageModalProps {
	message: {
		id: number
		email: string
		message: string
		createdAt: string
		isReplied: boolean
		isRead: boolean
	}
	onClose: () => void
	onReplySent?: () => void
}

export function ContactMessageModal({ message, onClose, onReplySent }: ContactMessageModalProps) {
	const [reply, setReply] = useState('')
	const [sending, setSending] = useState(false)
	const { toast } = useToast()
	const { t } = useTranslation('contact')

	const handleReplySubmit = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault()
		setSending(true)
		try {
			await sendContactReply(message.email, reply)
			toast({
				title: t('reply_sent_success'),
				variant: 'success',
			})
			setReply('')
			if (onReplySent) {
				onReplySent()
			}
			onClose()
		} catch (error) {
			console.error(error)
			toast({
				title: t('reply_sent_error'),
				variant: 'destructive',
			})
		} finally {
			setSending(false)
		}
	}

	return (
		<div className='fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50'>
			<div className='bg-card rounded-lg p-6 w-96 shadow-lg relative'>
				<Button onClick={onClose} variant='link' className='text-foreground absolute top-2 right-2'>
					<X className='w-6 h-6' size={24} />
				</Button>
				<h2 className='text-xl font-semibold mb-4 text-card-foreground'>{t('message_details')}</h2>
				<div className='mb-4'>
					<p className='text-sm text-card-foreground'>
						<strong>{t('email')}:</strong> {message.email}
					</p>
					<p className='text-sm text-card-foreground'>
						<strong>{t('date')}:</strong> {new Date(message.createdAt).toLocaleString()}
					</p>
					<p className='mt-2 text-card-foreground'>{message.message}</p>
				</div>
				<form onSubmit={handleReplySubmit}>
					<div className='mb-4'>
						<Label htmlFor='reply' className='block text-sm font-medium mb-1 text-card-foreground'>
							{t('reply')}
						</Label>
						<Textarea
							id='reply'
							value={reply}
							onChange={(e) => setReply(e.target.value)}
							className='w-full'
							rows={4}
							required
						/>
					</div>
					<div className='flex justify-end space-x-2'>
						<Button variant='outline' type='button' onClick={onClose}>
							{t('cancel')}
						</Button>
						<Button type='submit' disabled={sending}>
							{sending ? t('sending') : t('send')}
						</Button>
					</div>
				</form>
			</div>
		</div>
	)
}
