'use client'

import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import { Textarea } from '@/components/ui/textarea'
import { useToast } from '@/components/ui/use-toast'
import { sendContactMessage } from '@/services/api/contact'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

interface ContactFormModalProps {
	isOpen: boolean
	onClose: () => void
}

export function ContactFormModal({ isOpen, onClose }: ContactFormModalProps) {
	const [email, setEmail] = useState('')
	const [message, setMessage] = useState('')
	const [loading, setLoading] = useState(false)
	const { toast } = useToast()
	const { t } = useTranslation('contact')

	const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault()
		setLoading(true)
		try {
			await sendContactMessage(email, message)
			toast({
				description: t('message_sent_success'),
				variant: 'success',
			})
			setEmail('')
			setMessage('')
		} catch (error) {
			console.error(error)
			toast({
				description: t('message_sent_error'),
				variant: 'destructive',
			})
		} finally {
			setLoading(false)
		}
	}

	if (!isOpen) return null

	return (
		<div
			className='fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50'
			onClick={onClose}
			onKeyDown={(e) => {
				if (e.key === 'Enter' || e.key === ' ') {
					onClose()
				}
			}}
		>
			<div className='bg-card rounded-lg p-6 w-96 shadow-lg'>
				<h2 className='text-xl font-semibold mb-4 text-card-foreground'>{t('contact_form_title')}</h2>
				<form onSubmit={handleSubmit}>
					<div className='mb-4'>
						<Label htmlFor='contact-email' className='block text-sm font-medium mb-1 text-card-foreground'>
							{t('email')}
						</Label>
						<Input
							id='contact-email'
							type='email'
							value={email}
							onChange={(e) => setEmail(e.target.value)}
							className='w-full'
							required
						/>
					</div>
					<div className='mb-4'>
						<Label htmlFor='contact-message' className='block text-sm font-medium mb-1 text-card-foreground'>
							{t('message')}
						</Label>
						<Textarea
							id='contact-message'
							value={message}
							onChange={(e) => setMessage(e.target.value)}
							className='w-full'
							rows={4}
							required
						/>
					</div>
					<div className='flex justify-end space-x-2'>
						<Button variant='outline' type='button' onClick={onClose}>
							{t('cancel')}
						</Button>
						<Button type='submit' disabled={loading}>
							{loading ? t('sending') : t('send')}
						</Button>
					</div>
				</form>
			</div>
		</div>
	)
}
