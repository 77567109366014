'use client'

import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card'
import { GradientSpinner } from '@/components/ui/spinner'
import { fetchActiveEnrollments, fetchUserPhysicalData } from '@/services/api'
import type { UserProfile } from '@/types'
import { Apple, Book, Dumbbell, NotebookTabs, Pill, ScanSearch, User } from 'lucide-react'
import Link from 'next/link'
import type React from 'react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

const ClientHome: React.FC = () => {
	const { t } = useTranslation('client_home')
	const [programId, setProgramId] = useState<number | null>(null)
	const [loading, setLoading] = useState<boolean>(true)
	const [error, setError] = useState<string | null>(null)
	const [userProfile, setUserProfile] = useState<UserProfile | null>(null)

	useEffect(() => {
		const loadData = async () => {
			try {
				const [enrollments, profile] = await Promise.all([fetchActiveEnrollments(), fetchUserPhysicalData()])

				if (enrollments.length > 0) {
					setProgramId(enrollments[0].program.id)
				}

				setUserProfile(profile)
			} catch (err) {
				console.error(t('error_fetching_data'), err)
				setError(t('error_fetching_data'))
			} finally {
				setLoading(false)
			}
		}

		loadData()
	}, [t])

	if (loading) {
		return (
			<div className='flex justify-center items-center h-screen'>
				<GradientSpinner />
			</div>
		)
	}

	if (error) {
		return (
			<div className='flex justify-center items-center h-screen'>
				<p className='text-red-500'>{error}</p>
			</div>
		)
	}

	return (
		<main className='container mx-auto px-4 py-8'>
			<h1 className='text-3xl font-bold mb-6 text-center'>{t('welcome_client_area')}</h1>

			{/* Using `auto-rows-fr` to ensure all rows have uniform height.
				Each card is wrapped by a link (or div) with h-full to occupy the entire height. */}
			<div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 auto-rows-fr'>
				{/* Workout Card */}
				{programId ? (
					<Link href={`/programs/${programId}?protocolType=WORKOUT`} className='block h-full'>
						<Card className='h-full flex flex-col hover:shadow-lg transition-shadow duration-300'>
							<CardHeader className='flex items-center'>
								<Dumbbell className='text-primary m-2' size={32} />
								<CardTitle>{t('workout_title')}</CardTitle>
							</CardHeader>
							<CardContent className='flex-1 flex items-center justify-center text-center'>
								<CardDescription>{t('workout_description')}</CardDescription>
							</CardContent>
						</Card>
					</Link>
				) : (
					<Card className='h-full flex flex-col hover:shadow-lg transition-shadow duration-300'>
						<CardHeader className='flex items-center'>
							<Dumbbell className='text-muted dark:text-muted-foreground' size={32} />
							<CardTitle>{t('workout_title')}</CardTitle>
						</CardHeader>
						<CardContent className='flex-1 flex items-center justify-center text-center'>
							<CardDescription>{t('no_active_workout')}</CardDescription>
						</CardContent>
					</Card>
				)}

				{/* Diet Card */}
				{programId ? (
					<Link href={`/programs/${programId}?protocolType=DIET`} className='block h-full'>
						<Card className='h-full flex flex-col hover:shadow-lg transition-shadow duration-300'>
							<CardHeader className='flex items-center'>
								<Apple className='text-primary m-2' size={32} />
								<CardTitle>{t('diet_title')}</CardTitle>
							</CardHeader>
							<CardContent className='flex-1 flex items-center justify-center text-center'>
								<CardDescription>{t('diet_description')}</CardDescription>
							</CardContent>
						</Card>
					</Link>
				) : (
					<Card className='h-full flex flex-col hover:shadow-lg transition-shadow duration-300'>
						<CardHeader className='flex items-center'>
							<Apple className='text-muted dark:text-muted-foreground' size={32} />
							<CardTitle>{t('diet_title')}</CardTitle>
						</CardHeader>
						<CardContent className='flex-1 flex items-center justify-center text-center'>
							<CardDescription>{t('no_active_diet')}</CardDescription>
						</CardContent>
					</Card>
				)}

				{/* Protocols Card */}
				{programId ? (
					<Link href={`/programs/${programId}?protocolType=OTHER`} className='block h-full'>
						<Card className='h-full flex flex-col hover:shadow-lg transition-shadow duration-300'>
							<CardHeader className='flex items-center'>
								<Pill className='text-primary m-2' size={32} />
								<CardTitle>{t('protocols_title')}</CardTitle>
							</CardHeader>
							<CardContent className='flex-1 flex items-center justify-center text-center'>
								<CardDescription>{t('protocols_description')}</CardDescription>
							</CardContent>
						</Card>
					</Link>
				) : (
					<Card className='h-full flex flex-col hover:shadow-lg transition-shadow duration-300'>
						<CardHeader className='flex items-center'>
							<Pill className='text-muted dark:text-muted-foreground' size={32} />
							<CardTitle>{t('protocols_title')}</CardTitle>
						</CardHeader>
						<CardContent className='flex-1 flex items-center justify-center text-center'>
							<CardDescription>{t('no_active_protocols')}</CardDescription>
						</CardContent>
					</Card>
				)}

				{/* My Programs */}
				<Link href='/my-programs' className='block h-full'>
					<Card className='h-full flex flex-col hover:shadow-lg transition-shadow duration-300'>
						<CardHeader className='flex items-center'>
							<NotebookTabs className='text-primary m-2' size={32} />
							<CardTitle>{t('my_programs_title')}</CardTitle>
						</CardHeader>
						<CardContent className='flex-1 flex items-center justify-center text-center'>
							<CardDescription>{t('my_programs_description')}</CardDescription>
						</CardContent>
					</Card>
				</Link>

				{/* Explore */}
				<Link href='/programs' className='block h-full'>
					<Card className='h-full flex flex-col hover:shadow-lg transition-shadow duration-300'>
						<CardHeader className='flex items-center'>
							<ScanSearch className='text-primary m-2' size={32} />
							<CardTitle>{t('explore_title')}</CardTitle>
						</CardHeader>
						<CardContent className='flex-1 flex items-center justify-center text-center'>
							<CardDescription>{t('explore_description')}</CardDescription>
						</CardContent>
					</Card>
				</Link>

				{/* Blog */}
				<Link href='/blog' className='block h-full'>
					<Card className='h-full flex flex-col hover:shadow-lg transition-shadow duration-300'>
						<CardHeader className='flex items-center'>
							<Book className='text-primary m-2' size={32} />
							<CardTitle>{t('blog_title')}</CardTitle>
						</CardHeader>
						<CardContent className='flex-1 flex items-center justify-center text-center'>
							<CardDescription>{t('blog_description')}</CardDescription>
						</CardContent>
					</Card>
				</Link>

				{/* Generate Workout */}
				{userProfile ? (
					<Link href='/generate-plan' className='block h-full'>
						<Card className='h-full flex flex-col hover:shadow-lg transition-shadow duration-300'>
							<CardHeader className='flex items-center'>
								<Dumbbell className='text-primary m-2' size={32} />
								<CardTitle>{t('generate_workout_title')}</CardTitle>
							</CardHeader>
							<CardContent className='flex-1 flex items-center justify-center text-center'>
								<CardDescription>{t('generate_workout_description')}</CardDescription>
							</CardContent>
						</Card>
					</Link>
				) : (
					<Card className='h-full flex flex-col opacity-50 cursor-not-allowed'>
						<CardHeader className='flex items-center'>
							<Dumbbell className='text-muted dark:text-muted-foreground m-2' size={32} />
							<CardTitle>{t('generate_workout_title')}</CardTitle>
						</CardHeader>
						<CardContent className='flex-1 flex items-center justify-center text-center'>
							<CardDescription>{t('complete_physical_assessment')}</CardDescription>
						</CardContent>
					</Card>
				)}

				{/* Physical Assessment */}
				<Link href='/user-profile' className='block h-full'>
					<Card className='h-full flex flex-col hover:shadow-lg transition-shadow duration-300'>
						<CardHeader className='flex items-center'>
							<User className='text-primary m-2' size={32} />
							<CardTitle>{t('physical_assessment_title')}</CardTitle>
						</CardHeader>
						<CardContent className='flex-1 flex items-center justify-center text-center'>
							<CardDescription>{t('physical_assessment_description')}</CardDescription>
						</CardContent>
					</Card>
				</Link>
			</div>
		</main>
	)
}

export { ClientHome }
