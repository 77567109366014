'use client'
import { ContactFormModal } from '@/components'
import { LanguageChanger } from '@/components'
import Link from 'next/link'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

export function Footer() {
	const { t } = useTranslation('footer')
	const [isContactModalOpen, setContactModalOpen] = useState(false)

	const scrollToFAQ = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
		event.preventDefault()
		const faqSection =
			window.innerWidth < 768
				? document.getElementById('faq-section-mobile')
				: document.getElementById('faq-section-desktop')
		if (faqSection) {
			faqSection.scrollIntoView({ behavior: 'smooth', block: 'center' })
		}
	}

	return (
		<>
			<ContactFormModal isOpen={isContactModalOpen} onClose={() => setContactModalOpen(false)} />

			{/* Mobile Version */}
			<div className='block md:hidden'>
				<footer className='bg-[#0f172a] text-[#f3f4f6] w-full pt-12 pb-6 px-4'>
					<div className='flex flex-col gap-6'>
						{/* About Us */}
						<div className='flex flex-col gap-2'>
							<h3 className='text-lg font-bold'>{t('footer_about_us_title')}</h3>
							<nav className='flex flex-col gap-1 text-base font-medium'>
								<Link href='https://buildupfit.com/blog'>{t('footer_blog')}</Link>
								<Link href='#' onClick={scrollToFAQ}>
									{t('footer_faq')}
								</Link>
								<Link href='https://buildupfit.com/privacy-policy'>{t('footer_privacy_policy')}</Link>
							</nav>
						</div>

						{/* Our Networks */}
						<div className='flex flex-col gap-2'>
							<h3 className='text-lg font-bold'>{t('footer_our_networks_title')}</h3>
							<nav className='flex flex-col gap-1 text-base font-medium'>
								<Link href='#'>{t('footer_instagram')}</Link>
								<Link href='#'>{t('footer_facebook')}</Link>
							</nav>
						</div>

						{/* Contact Us */}
						<div className='flex flex-col gap-2'>
							<h3 className='text-lg font-bold'>{t('footer_contact_us_title')}</h3>
							<button
								type='button'
								className='text-base font-medium cursor-pointer bg-transparent border-0 p-0'
								onClick={() => setContactModalOpen(true)}
							>
								{t('footer_contact_button')}
							</button>
						</div>

						{/* Brand */}
						<div className='flex flex-col items-center'>
							<h1 className='text-4xl font-black'>{t('footer_brand_build')}</h1>
							<span className='text-6xl font-black'>{t('footer_brand_up')}</span>
						</div>
					</div>

					<div className='mt-8'>
						<div className='flex flex-col sm:flex-row items-center justify-center space-y-2 sm:space-y-0 sm:space-x-4 mb-4'>
							<p>&copy; 2024 Build Up!. {t('all_rights_reserved')}</p>
							<Link href='/privacy-policy' className='hover:underline'>
								{t('privacy_policy')}
							</Link>
						</div>
						<div className='flex items-center justify-center space-x-4 mt-4'>
							<LanguageChanger />
						</div>
					</div>
				</footer>
			</div>

			{/* Desktop Version */}
			<div className='hidden md:block'>
				<footer className='bg-[#0f172a] text-[#f3f4f6] w-full pt-[120px] pb-[60px] px-8'>
					<div className='flex flex-row gap-10 container'>
						{/* About Us */}
						<div className='flex flex-col gap-4 w-[200px]'>
							<h3 className='text-xl font-bold'>{t('footer_about_us_title')}</h3>
							<nav className='flex flex-col gap-2 text-base font-medium'>
								<Link href='https://buildupfit.com/blog'>{t('footer_blog')}</Link>
								<Link href='#' onClick={scrollToFAQ}>
									{t('footer_faq')}
								</Link>
								<Link href='https://buildupfit.com/privacy-policy'>{t('footer_privacy_policy')}</Link>
							</nav>
						</div>

						{/* Our Networks */}
						<div className='flex flex-col gap-4 w-[200px]'>
							<h3 className='text-xl font-bold'>{t('footer_our_networks_title')}</h3>
							<nav className='flex flex-col gap-2 text-base font-medium'>
								<Link href='#'>{t('footer_instagram')}</Link>
								<Link href='#'>{t('footer_facebook')}</Link>
							</nav>
						</div>

						{/* Contact Us */}
						<div className='flex flex-col gap-4 w-[200px]'>
							<h3 className='text-xl font-bold'>{t('footer_contact_us_title')}</h3>
							<button
								type='button'
								className='text-base font-medium cursor-pointer bg-transparent border-0 p-0 text-left'
								onClick={() => setContactModalOpen(true)}
							>
								{t('footer_contact_button')}
							</button>
						</div>

						{/* Brand */}
						<div className='flex flex-col justify-end ml-auto'>
							<div className='flex items-end'>
								<h1 className='text-[96px] font-black mr-4 leading-none'>{t('footer_brand_build')}</h1>
								<span className='text-[160px] font-black leading-none'>{t('footer_brand_up')}</span>
							</div>
						</div>
					</div>

					{/* Seção Inferior: Direitos Reservados e Language Changer */}
					<div className='container mx-auto px-4'>
						<div className='flex flex-col sm:flex-row items-center justify-center space-y-2 sm:space-y-0 sm:space-x-4 '>
							<p>&copy; 2024 Build Up!. {t('all_rights_reserved')}</p>
						</div>
						<div className='flex items-center justify-center space-x-4'>
							<LanguageChanger />
						</div>
					</div>
				</footer>
			</div>
		</>
	)
}
