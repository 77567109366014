'use client'

import { zodResolver } from '@hookform/resolvers/zod'
import { ArrowLeft, X } from 'lucide-react'
import { useSession } from 'next-auth/react'
import { useRouter } from 'next/navigation'
/* --------------------- External Libraries --------------------- */
import { useEffect, useState } from 'react'
import type React from 'react'
import { type SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import Modal from 'react-modal'
import type { z } from 'zod'

/* --------------------- Internal Components --------------------- */
import { MultiSelect, ProgramProtocolsTable } from '@/components'
import { CustomInput } from '@/components'
import { ImageUpload } from '@/components'
import TipTapEditor from '@/components/tiptap-editor'
import { Button } from '@/components/ui/button'
import { Form, FormControl, FormField, FormItem, FormMessage } from '@/components/ui/form'
import { Switch } from '@/components/ui/switch'
import { Title } from '@/components/ui/title'
import { useToast } from '@/components/ui/use-toast'
import ProtocolForm from './protocol-form'

/* --------------------- Internal Hooks & Services --------------------- */
import { useProgramSchema } from '@/lib'
import {
	createProgram,
	deleteProgramProtocol,
	enrollClientsInProgram,
	fetchProtocols,
	fetchUsers,
	linkProtocolToProgram,
	removeClientsFromProgram,
	updateProgram,
} from '@/services/api'
import type { AppUser, ProgramFormProps, ProgramProtocol, Protocol } from '@/types'

const ProgramForm: React.FC<ProgramFormProps> = ({ initialProgram }) => {
	// Get session, user, toast, translation, and router data
	const { data: session } = useSession()
	const user = session?.user
	const { toast } = useToast()
	const { t } = useTranslation('programs')
	const router = useRouter()
	const userId = user?.id
	const userRole = user?.role

	// Component state declarations
	const [clients, setClients] = useState<AppUser[]>([])
	const [programId, setProgramId] = useState<number | null>(initialProgram ? initialProgram.id : null)
	const [originalClientIds, setOriginalClientIds] = useState<string[]>([])
	const [modalIsOpen, setModalIsOpen] = useState(false)
	const [isProgramSaved, setIsProgramSaved] = useState(false)
	const [isSubmitting, setIsSubmitting] = useState(false)
	const [protocols, setProtocols] = useState<ProgramProtocol[]>([])
	const [currentProtocol, setCurrentProtocol] = useState<Protocol | undefined>(undefined)

	const programSchema = useProgramSchema()

	// Initialize form with default values based on initialProgram
	const form = useForm<z.infer<typeof programSchema>>({
		resolver: zodResolver(programSchema),
		defaultValues: initialProgram
			? {
					...initialProgram,
					private: initialProgram.private,
					featured: initialProgram.featured,
					clientIds: initialProgram.enrollments
						? initialProgram.enrollments.map((enrollment) => enrollment.userId.toString())
						: [],
					program_description: initialProgram.program_description ?? '',
					program_cover: initialProgram.program_cover ?? null,
				}
			: {
					program_name: '',
					goal: '',
					duration_in_weeks: 0,
					program_description: '',
					clientIds: [],
					program_cover: null,
					private: true,
					featured: false,
				},
		mode: 'onChange',
	})

	/* --------------------- Effects --------------------- */
	// Watch for changes in the form and mark the program as unsaved when changes occur
	useEffect(() => {
		const subscription = form.watch(() => {
			setIsProgramSaved((prev) => (prev ? false : prev))
		})
		return () => subscription.unsubscribe()
	}, [form])

	// Fetch protocols if a program has been created (i.e. programId exists)
	useEffect(() => {
		if (programId) {
			fetchProtocols(programId).then(setProtocols).catch(console.error)
		}
	}, [programId])

	// Set original client IDs from initial program enrollments
	useEffect(() => {
		if (initialProgram?.enrollments) {
			const clientIds = initialProgram.enrollments.map((enrollment) => enrollment.userId.toString())
			setOriginalClientIds(clientIds)
		}
	}, [initialProgram])

	// If the user is a coach or admin, fetch the active clients
	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	useEffect(() => {
		if (user && (user.role === 'COACH' || user.role === 'ADMIN')) {
			fetchUsers({ role: 'CLIENT', status: 'ACTIVE' })
				.then(setClients)
				.catch((error) => {
					console.error('Error fetching clients:', error)
				})
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user?.id])

	/* --------------------- Modal Handlers --------------------- */
	const openModal = (protocol?: Protocol) => {
		setCurrentProtocol(protocol)
		setModalIsOpen(true)
	}

	const closeModal = () => {
		setModalIsOpen(false)
	}

	/* --------------------- Protocol Handlers --------------------- */
	// Handle protocol save: link protocol to the program and update state
	const onProtocolSaved = async (protocol: Protocol) => {
		if (!programId) return
		try {
			const newProgramProtocol = await linkProtocolToProgram(programId, protocol.id)
			setProtocols((prevProtocols) => {
				const existingIndex = prevProtocols.findIndex((p) => p.protocol.id === protocol.id)
				if (existingIndex !== -1) {
					// Update existing protocol
					const updatedProtocols = [...prevProtocols]
					updatedProtocols[existingIndex] = {
						...prevProtocols[existingIndex],
						...newProgramProtocol,
						protocol,
					}
					return updatedProtocols
				}
				// Add new protocol
				return [
					...prevProtocols,
					{
						...newProgramProtocol,
						protocol,
					},
				]
			})
		} catch (error) {
			console.error('Error linking protocol to program:', error)
		}
	}

	// Handle program form submission
	const onSubmitProgram: SubmitHandler<z.infer<typeof programSchema>> = async (data) => {
		if (isSubmitting) return
		setIsSubmitting(true)

		try {
			const { clientIds = [], program_cover, private: isPrivate, featured, ...restData } = data

			// Create a FormData object to send file data if available
			const formData = new FormData()
			formData.append('program_name', restData.program_name)
			formData.append('goal', restData.goal)
			formData.append('duration_in_weeks', restData.duration_in_weeks.toString())
			formData.append('program_description', restData.program_description || '')
			formData.append('clientIds', JSON.stringify(clientIds))
			formData.append('private', String(isPrivate))
			formData.append('featured', String(featured))

			if (program_cover instanceof File) {
				formData.append('program_cover', program_cover)
			}

			let createdProgram = null

			// Update an existing program or create a new one
			if (programId) {
				createdProgram = await updateProgram(programId, formData)
			} else {
				createdProgram = await createProgram(formData)
				setProgramId(createdProgram.id)
			}

			// Enroll clients if clientIds are provided
			if (clientIds.length > 0) {
				await enrollClientsInProgram(
					createdProgram.id,
					clientIds.map((id) => Number.parseInt(id, 10)),
				)
			}

			// Remove clients that were deselected
			const removedClientIds = originalClientIds.filter((originalId) => !clientIds.includes(originalId))
			if (removedClientIds.length > 0) {
				await removeClientsFromProgram(
					createdProgram.id,
					removedClientIds.map((id) => Number.parseInt(id, 10)),
				)
			}

			setIsProgramSaved(true)
			toast({ description: t('program_saved_success'), variant: 'success' })
		} catch (error: any) {
			console.error('Error saving program:', error)
			if (error.response) {
				console.error('Error response data:', error.response.data)
			}
			toast({ description: t('error_saving_program'), variant: 'destructive' })
		} finally {
			setIsSubmitting(false)
		}
	}

	// Handle deletion of a program protocol
	const handleDeleteProtocol = async (protocol: ProgramProtocol) => {
		if (!protocol || !programId) return
		try {
			await deleteProgramProtocol(protocol.id)
			setProtocols((prevProtocols) => prevProtocols.filter((p) => p.id !== protocol.id))
			toast({ description: t('delete_protocol_success'), variant: 'success' })
		} catch (error) {
			toast({
				description: t('delete_protocol_error'),
				variant: 'destructive',
			})
		}
	}

	/* --------------------- Render --------------------- */
	return (
		<div className='container mx-auto max-w-3xl p-4'>
			{/* Display header with back button if editing an existing program */}
			{initialProgram && (
				<div className='flex items-center mb-4'>
					<ArrowLeft className='h-6 w-6 mr-2 cursor-pointer' onClick={() => router.back()} />
					<Title>{initialProgram.program_name}</Title>
				</div>
			)}
			<Form {...form}>
				<form onSubmit={form.handleSubmit(onSubmitProgram)} className='space-y-4'>
					<CustomInput
						control={form.control}
						name='program_name'
						label={t('program_name')}
						placeholder={t('program_name')}
					/>

					<FormField
						control={form.control}
						name='program_description'
						render={({ field }) => (
							<FormItem>
								<FormControl>
									<TipTapEditor content={field.value ?? ''} onChange={field.onChange} />
								</FormControl>
								<FormMessage />
							</FormItem>
						)}
					/>

					<CustomInput control={form.control} name='goal' label={t('goal')} placeholder={t('goal')} />

					<CustomInput
						control={form.control}
						name='duration_in_weeks'
						label={t('duration_in_weeks')}
						placeholder={t('duration_in_weeks')}
						type='number'
					/>

					{/* Field for cover image upload */}
					<FormField
						control={form.control}
						name='program_cover'
						render={({ field }) => (
							<FormItem>
								<FormControl>
									<ImageUpload name='program_cover' />
								</FormControl>
								<FormMessage />
							</FormItem>
						)}
					/>

					{/* Show switch for public/private if user is ADMIN or COACH */}
					{user && (user.role === 'ADMIN' || user.role === 'COACH') && (
						<FormField
							control={form.control}
							name='private'
							render={({ field }) => (
								<FormItem className='flex flex-col'>
									<FormControl>
										{/* Invert the value: switch ON = public (private = false) */}
										<Switch checked={!field.value} onCheckedChange={(checked) => field.onChange(!checked)} />
									</FormControl>
									<p className='text-sm text-muted-foreground'>
										Making a program public will make it visible to all users on the platform and anyone can enroll in
										it.
									</p>
								</FormItem>
							)}
						/>
					)}
					{user && (user.role === 'ADMIN' || user.role === 'COACH') && (
						<FormField
							control={form.control}
							name='featured'
							render={({ field }) => (
								<FormItem className='flex flex-col'>
									<FormControl>
										<Switch checked={field.value} onCheckedChange={field.onChange} />
									</FormControl>
									<p className='text-sm text-muted-foreground'>
										Mark this program as featured to highlight it on the platform.
									</p>
								</FormItem>
							)}
						/>
					)}
					{/* Display client selection if user is COACH or ADMIN */}
					{user && (userRole === 'COACH' || userRole === 'ADMIN') && (
						<FormField
							control={form.control}
							name='clientIds'
							render={({ field }) => (
								<FormItem>
									<FormControl>
										<MultiSelect
											control={form.control}
											name='clientIds'
											options={clients.map((client) => ({
												value: client.id.toString(),
												label: `${client.name} (${client.username})`,
											}))}
											placeholder={t('select_clients')}
										/>
									</FormControl>
									<FormMessage />
								</FormItem>
							)}
						/>
					)}

					<div className='w-full flex justify-end'>
						<Button
							type='submit'
							disabled={!form.formState.isValid || isSubmitting}
							className={isProgramSaved ? 'bg-gray-400' : 'bg-primary'}
						>
							{t('save_program')}
						</Button>
					</div>
				</form>
			</Form>

			{/* If a program has been created, allow protocol management */}
			{programId && (
				<>
					<div className='w-full flex justify-end items-end py-2'>
						<Button type='button' onClick={() => openModal()} variant='secondary'>
							{t('add_protocol')}
						</Button>
					</div>

					<div className='mt-4 w-full'>
						{protocols.length > 0 && (
							<ProgramProtocolsTable
								protocols={protocols}
								mode='form'
								openModal={openModal}
								openDeleteDialog={handleDeleteProtocol}
							/>
						)}
					</div>

					{/* Modal for adding or editing a protocol */}
					<Modal
						isOpen={modalIsOpen}
						onRequestClose={closeModal}
						ariaHideApp={false}
						className='fixed inset-0 flex items-center justify-center p-4 my-16'
						overlayClassName='fixed inset-0 bg-black bg-opacity-50 z-30'
					>
						<div className='bg-background rounded-lg shadow-lg w-full max-w-3xl max-h-full flex flex-col'>
							<div className='overflow-y-auto p-6'>
								<div className='flex justify-between items-center ml-4'>
									<h3 className='text-xl font-bold'>{t('protocol')}</h3>
									<Button onClick={closeModal} variant='link' className='text-foreground'>
										<X className='w-6 h-6' size={24} />
									</Button>
								</div>
								<ProtocolForm
									programId={programId}
									initialProtocol={currentProtocol}
									onProtocolSaved={onProtocolSaved}
								/>
							</div>
						</div>
					</Modal>
				</>
			)}
		</div>
	)
}

export { ProgramForm }
