'use client'

import { Footer, Header, RoleSelectionDialog, Sidebar } from '@/components'
import { updateUserRole } from '@/services/api'
import { useSession } from 'next-auth/react'
import { usePathname } from 'next/navigation'
import { useEffect, useState } from 'react'

export const ClientLayout = ({ children }: { children: React.ReactNode }) => {
	const pathname = usePathname()
	const isAuthPage = pathname === '/login' || pathname === '/sign-up'
	const isDashboardRoute = pathname.includes('/dashboard')
	const isLandingPage = pathname.includes('/')

	const { data: session, status, update } = useSession()
	const [dialogOpen, setDialogOpen] = useState(false)
	const [roleHandled, setRoleHandled] = useState(false)

	useEffect(() => {
		// Open the role selection dialog if the user is authenticated and hasn't selected a role yet
		if (status === 'authenticated' && session.user && !session.user.hasSelectedRole && !roleHandled) {
			setDialogOpen(true)
		}
	}, [session, status, roleHandled])

	const handleRoleSelect = async (role: 'CLIENT' | 'COACH') => {
		try {
			await updateUserRole(role)
			await update()
			setDialogOpen(false)
			setRoleHandled(true)
		} catch (error) {
			console.error('Error updating user role:', error)
		}
	}

	return (
		<>
			{dialogOpen && <RoleSelectionDialog open={dialogOpen} onSelectRole={handleRoleSelect} />}

			<div className='flex min-h-screen'>
				{/* Fixed Sidebar */}
				{isDashboardRoute && (
					<div className='hidden lg:flex fixed left-0 top-0 h-screen w-64 border-r z-50'>
						<Sidebar />
					</div>
				)}

				{/* Main content area with scrollable content */}
				<div className={`flex-1 flex flex-col ${isDashboardRoute ? 'lg:ml-64' : ''}`}>
					{!isAuthPage && <Header />}

					{/* Content area with vertical scrolling */}
					<main className='flex-1 overflow-y-auto'>{children}</main>

					{!isAuthPage && !isDashboardRoute && !isLandingPage && <Footer />}
				</div>
			</div>
		</>
	)
}
