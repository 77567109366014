import api from './api'

export interface ContactMessage {
	id: number
	email: string
	message: string
	createdAt: string
	isRead: boolean
	isReplied: boolean
}

export const sendContactMessage = async (email: string, message: string): Promise<ContactMessage> => {
	const response = await api.post<ContactMessage>('/contact', { email, message })
	return response.data
}

export const fetchContactMessages = async (): Promise<ContactMessage[]> => {
	const response = await api.get<ContactMessage[]>('/contact')
	return response.data
}

export const sendContactReply = async (to: string, reply: string): Promise<void> => {
	const response = await api.post('/contact/reply', { to, reply })
	return response.data
}

export const deleteContactMessage = async (id: number): Promise<void> => {
	await api.delete(`/contact/${id}`)
}

export const markMessageAsRead = async (id: number): Promise<ContactMessage> => {
	const response = await api.patch<ContactMessage>(`/contact/${id}/read`)
	return response.data
}

export const markAllMessagesAsRead = async (): Promise<void> => {
	await api.patch('/contact/read-all')
}
