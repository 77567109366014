'use client'

import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from '@/components/ui/card'
import type { Program, ProgramEnrollment } from '@/types'
import clsx from 'clsx'
import { Image as ImageIcon } from 'lucide-react'
import Image from 'next/image'
import { useRouter } from 'next/navigation'
import type React from 'react'
import { useTranslation } from 'react-i18next'

interface ProgramCardProps {
	program: Program
	enrollment?: ProgramEnrollment
}

const ProgramCard: React.FC<ProgramCardProps> = ({ program, enrollment }) => {
	const { t } = useTranslation('programs')
	const router = useRouter()

	return (
		<Card
			key={program.id}
			className='w-full cursor-pointer hover:shadow-md border-b-2 border-card shadow-sm rounded-md my-2'
			onClick={() => router.push(`/dashboard/programs/${program.id}`)}
		>
			<div className='flex'>
				{/* Left side: Program cover image */}
				<div className='flex-shrink-0 w-20 h-20 m-4'>
					{typeof program.program_cover === 'string' && program.program_cover ? (
						<Image
							src={program.program_cover}
							alt={program.program_name}
							width={80}
							height={80}
							className='w-full h-full rounded-md border-2 border-white shadow-md object-cover'
						/>
					) : (
						<div
							className={clsx(
								'w-full h-full flex items-center justify-center rounded-md border-2 border-white shadow-md bg-gray-200',
							)}
						>
							<ImageIcon className='w-10 h-10 text-gray-500' />
						</div>
					)}
				</div>

				{/* Right side: Program details */}
				<div className='flex-1 pr-4 py-4'>
					<CardHeader className='p-0'>
						<CardTitle className='text-xl font-semibold'>{program.program_name}</CardTitle>
						<CardDescription>{program.goal}</CardDescription>
					</CardHeader>
					<CardContent className='p-0 mt-2 text-card-foreground'>
						<div
							className='leading-normal'
							// biome-ignore lint/security/noDangerouslySetInnerHtml: Conteúdo vindo do backend
							dangerouslySetInnerHTML={{
								__html:
									program.program_description && program.program_description.length > 200
										? `${program.program_description.substring(0, 200)}...`
										: program.program_description || '',
							}}
						/>
					</CardContent>
					<CardFooter className='p-0 mt-2'>
						<p className='text-sm'>
							{t('duration')}: {program.duration_in_weeks} {t('weeks')}
						</p>
					</CardFooter>
				</div>
			</div>
		</Card>
	)
}

export { ProgramCard }
