'use client'
import { useSession } from 'next-auth/react'
import { useRouter } from 'next/navigation'
import type React from 'react'
import { useEffect } from 'react'

export function withAuth<P extends object>(
	WrappedComponent: React.ComponentType<P>,
	allowedRoles: string[],
): React.FC<P> {
	const WithAuth: React.FC<P> = (props) => {
		const { data: session, status } = useSession()
		const router = useRouter()

		useEffect(() => {
			if (status !== 'loading') {
				if (!session) {
					router.push('/login')
				} else if (!allowedRoles.includes(session.user.role)) {
					router.push('/unauthorized')
				}
			}
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [session, status, router, allowedRoles])

		if (status === 'loading' || !session || (session && !allowedRoles.includes(session.user.role))) {
			return <div>Carregando...</div>
		}

		return <WrappedComponent {...props} />
	}

	WithAuth.displayName = `withAuth(${WrappedComponent.displayName || WrappedComponent.name || 'Component'})`
	return WithAuth
}
