// components/CustomInput.tsx
'use client'

import { FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import type React from 'react'
import type { Control, FieldPath, FieldValues } from 'react-hook-form'

interface CustomInputProps<T extends FieldValues> {
	control: Control<T>
	name: FieldPath<T>
	label?: string
	placeholder: string
	type?: React.HTMLInputTypeAttribute
	className?: string
}

const CustomInput = <T extends FieldValues>({
	control,
	name,
	label,
	placeholder,
	type = 'text',
	className,
}: CustomInputProps<T>) => {
	return (
		<FormField
			control={control}
			name={name}
			render={({ field }) => (
				<FormItem className='mb-2'>
					<FormLabel>{label}</FormLabel>
					<FormControl>
						<Input
							type={type}
							placeholder={placeholder}
							className={`input-class my-2 w-full ${className}`}
							{...field}
							onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
								let value: string | number = e.target.value
								if (type === 'number') {
									value = value === '' ? '' : Number(value)
								}
								field.onChange(value)
							}}
						/>
					</FormControl>
					<FormMessage />
				</FormItem>
			)}
		/>
	)
}

export { CustomInput }
