'use client'

import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar'
import { Badge } from '@/components/ui/badge'
import { Button } from '@/components/ui/button'
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '@/components/ui/dropdown-menu'
import { ModeToggle } from '@/components/ui/mode-toggle'
import { Bell } from 'lucide-react'
import { signOut, useSession } from 'next-auth/react'
import Link from 'next/link'
import { usePathname } from 'next/navigation'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { LanguageChanger } from './language-changer'

export const UserMenu = () => {
	const { t } = useTranslation('header')
	const { data: session, status } = useSession()
	const pathname = usePathname()
	const isBlogPage = pathname.startsWith('/blog') || pathname.startsWith('/posts/')

	const isLoading = status === 'loading'
	const isAuthenticated = status === 'authenticated'

	if (isLoading) {
		return <span>{t('loading')}</span>
	}

	if (!isAuthenticated) {
		return (
			<div className='flex items-center gap-2'>
				<Link href='/blog'>
					<Button variant='link' className='h-auto text-accent-foreground'>
						{t('blog')}
					</Button>
				</Link>
				<Link href='/login'>
					<Button variant='outline'>{t('login')}</Button>
				</Link>
				<Link href='/sign-up'>
					<Button>{t('create_account')}</Button>
				</Link>
			</div>
		)
	}

	const handleLogout = async () => {
		await signOut({ callbackUrl: '/' })
	}

	return (
		<div className='flex items-center'>
			{isBlogPage && (
				<Link href='/posts/create'>
					<Button className='mx-2'>{t('create_post')}</Button>
				</Link>
			)}
			<div className='mr-3'>
				<LanguageChanger />
			</div>
			{/* Badge de notificações visível apenas para usuários ADMIN */}
			{session?.user?.role === 'ADMIN' && (
				<Link href='/dashboard/contact-messages' className='mr-3' aria-label='Notifications'>
					<Badge variant='secondary' className='cursor-pointer'>
						<Bell size={18} />
					</Badge>
				</Link>
			)}

			<DropdownMenu>
				<DropdownMenuTrigger>
					<Avatar className='sm:mr-5 md:mr-0 cursor-pointer'>
						<AvatarImage
							src={
								typeof session?.user?.user_avatar === 'string'
									? session.user.user_avatar
									: 'https://github.com/shadcn.png'
							}
							alt={session?.user?.name || '@shadcn'}
						/>
						<AvatarFallback>{session?.user?.name?.slice(0, 2).toUpperCase() || 'CN'}</AvatarFallback>
					</Avatar>
				</DropdownMenuTrigger>
				<DropdownMenuContent align='end'>
					<DropdownMenuItem>
						<Link href='/profile' className='w-full'>
							<Button className='w-full justify-start' variant='ghost'>
								{t('profile')}
							</Button>
						</Link>
					</DropdownMenuItem>
					<DropdownMenuItem className='hidden sm:flex'>
						<Link href='/dashboard' className='w-full'>
							<Button className='w-full justify-start' variant='ghost'>
								Dashboard
							</Button>
						</Link>
					</DropdownMenuItem>
					<DropdownMenuItem>
						<Link href='/posts' className='w-full'>
							<Button className='w-full justify-start' variant='ghost'>
								{t('my_posts')}
							</Button>
						</Link>
					</DropdownMenuItem>

					{(session?.user?.role === 'COACH' || session?.user?.role === 'ADMIN') && (
						<DropdownMenuItem className='hidden sm:flex'>
							<Link href='/protocols' className='w-full'>
								<Button className='w-full justify-start' variant='ghost'>
									{t('protocols')}
								</Button>
							</Link>
						</DropdownMenuItem>
					)}

					<DropdownMenuItem>
						<Button className='w-full justify-start' variant='ghost' onClick={handleLogout}>
							{t('sign_out')}
						</Button>
					</DropdownMenuItem>
					<DropdownMenuItem>
						<ModeToggle />
					</DropdownMenuItem>
				</DropdownMenuContent>
			</DropdownMenu>
		</div>
	)
}

export default UserMenu
