'use client'

import { zodResolver } from '@hookform/resolvers/zod'
import Lottie from 'lottie-react'
import { ArrowLeft } from 'lucide-react'
import { useRouter } from 'next/navigation'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { ControlledMultiSelect, CustomInput, CustomSelect } from '@/components'
import { Button } from '@/components/ui/button'
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form'
import { Title } from '@/components/ui/title'
import { useToast } from '@/components/ui/use-toast'
import { useGenerateTrainingSchema } from '@/lib/useSchemas'
import { generateTrainingPlan, getTrainingTechniques } from '@/services/api'
import type { GenerateTrainingPlanRequest, GenerateTrainingPlanResponse, TrainingTechnique } from '@/types'
import loadingAnimation from '../../public/animations/loading.json'

interface GenerateTrainingPlanFormData {
	trainingDaysPerWeek: number
	preferredTrainingTime: 'MORNING' | 'AFTERNOON' | 'EVENING'
	sessionDuration: number
	advancedTechniques?: string[]
}

const GenerateWorkoutPlanForm: React.FC = () => {
	const { toast } = useToast()
	const router = useRouter()
	const generateTrainingSchema = useGenerateTrainingSchema()
	const { t } = useTranslation('generate_plan')

	const [techniques, setTechniques] = useState<TrainingTechnique[]>([])
	const [loadingTechniques, setLoadingTechniques] = useState<boolean>(true)
	const [isButtonClicked, setIsButtonClicked] = useState<boolean>(false)

	const form = useForm<GenerateTrainingPlanFormData>({
		resolver: zodResolver(generateTrainingSchema),
		defaultValues: {
			trainingDaysPerWeek: 3,
			preferredTrainingTime: 'MORNING',
			sessionDuration: 60,
			advancedTechniques: [],
		},
	})

	useEffect(() => {
		const fetchTechniques = async () => {
			try {
				const fetchedTechniques = await getTrainingTechniques()
				setTechniques(fetchedTechniques)
			} catch (error: any) {
				toast({
					description: error.message || t('error_fetch_techniques'),
					variant: 'destructive',
				})
			} finally {
				setLoadingTechniques(false)
			}
		}
		fetchTechniques()
	}, [toast, t])

	const onSubmit = async (data: GenerateTrainingPlanFormData) => {
		setIsButtonClicked(true)
		try {
			const requestData: GenerateTrainingPlanRequest = {
				trainingDaysPerWeek: data.trainingDaysPerWeek,
				preferredTrainingTime: data.preferredTrainingTime,
				sessionDuration: data.sessionDuration,
				advancedTechniques: data.advancedTechniques,
			}

			const response: GenerateTrainingPlanResponse = await generateTrainingPlan(requestData)

			toast({
				description: response.message || t('success_generate_plan'),
				variant: 'success',
			})

			form.reset()
		} catch (error: any) {
			console.error('Error generating training plan:', error)
			toast({
				description: error.message || t('error_generate_plan'),
				variant: 'destructive',
			})
		} finally {
			setIsButtonClicked(false)
		}
	}

	return (
		<div className='container mx-auto px-3 py-4 max-w-sm sm:max-w-3xl'>
			<div className='flex items-center mb-4'>
				<ArrowLeft className='h-6 w-6 mr-2 cursor-pointer' onClick={() => router.back()} />
				<Title>{t('generate_workout')}</Title>
			</div>

			{/* Displays the loading animation while the form is being submitted */}
			{form.formState.isSubmitting && (
				<div className='flex flex-col justify-center items-center mb-4'>
					<Lottie animationData={loadingAnimation} loop={true} style={{ width: 100, height: 100 }} />
					<span>
						<p>{t('creating_workout')}</p>
					</span>
				</div>
			)}

			<Form {...form}>
				<form onSubmit={form.handleSubmit(onSubmit)} className='space-y-4'>
					<CustomInput
						control={form.control}
						name='trainingDaysPerWeek'
						label={t('training_frequency_label')}
						type='number'
						placeholder={t('training_frequency_placeholder')}
					/>

					<CustomSelect
						control={form.control}
						name='preferredTrainingTime'
						label={t('preferred_time_label')}
						placeholder={t('preferred_time_placeholder')}
						options={[
							{ value: 'MORNING', label: t('morning') },
							{ value: 'AFTERNOON', label: t('afternoon') },
							{ value: 'EVENING', label: t('evening') },
						]}
					/>

					<CustomInput
						control={form.control}
						name='sessionDuration'
						label={t('session_duration_label')}
						type='number'
						placeholder={t('session_duration_placeholder')}
					/>

					<FormField
						control={form.control}
						name='advancedTechniques'
						render={({ field }) => (
							<FormItem>
								<FormLabel>{t('advanced_techniques_label')}</FormLabel>
								<FormControl>
									<ControlledMultiSelect
										field={field}
										options={
											loadingTechniques
												? []
												: techniques.map((tech) => ({
														value: tech.name,
														label: tech.name.replace('_', ' '),
													}))
										}
										placeholder={
											loadingTechniques ? t('advanced_techniques_loading') : t('advanced_techniques_placeholder')
										}
									/>
								</FormControl>
								<FormMessage />
							</FormItem>
						)}
					/>

					<Button
						type='submit'
						disabled={isButtonClicked || form.formState.isSubmitting}
						className='w-44 flex justify-center items-center gap-2'
					>
						{isButtonClicked || form.formState.isSubmitting ? <>{t('generating_button')}</> : t('generate_workout')}
					</Button>
				</form>
			</Form>
		</div>
	)
}

export { GenerateWorkoutPlanForm }
